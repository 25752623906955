<script>
import {Line} from 'vue-chartjs'
export default {
  extends: Line,
  props: ['data'],
  mounted() {
    this.renderChart(
      {
        labels: this.data.labels,
        datasets: [
          {
            label: 'Shoutouts sent',
            data: this.data.datasets.shoutouts,
            backgroundColor: 'rgba(84, 41, 129, 0.5)',
            borderColor: 'rgb(84, 41, 129)',
            borderWidth: 1
          },
          {
            label: 'Awards given',
            data: this.data.datasets.awards,
            backgroundColor: 'rgba(216, 112, 140, 0.5)',
            borderColor: 'rgb(216, 112, 140)',
            borderWidth: 1
          }
        ]
      },
      {
        aspectRatio: 1.2,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            ticks: {
              suggestedMin: 0,
              beginAtZero: true,
              precision: 1,
              callback: function(value) {if (value % 1 === 0) {return value;}}
            }
          }]
        }
      }
    )
  }
}
</script>